<template>
	<div>
		<a-spin :spinning="loading">
			<div style="min-height: 800px;" class="bg-w flex ">
				<div class="partner-shareholder-detail-left">
					<div class="partner-shareholder-detail-left-header">
						<div class="flex alcenter">
							<img  src="../../assets/image/woyaoyou_head_defult@2x.png" class="partner-shareholder-detail-face"/>
							<div class="ml15">
								<div class="flex alcenter ">
									<div class="ft16 ftw600 cl-main text-over4">{{detail.name}}</div>
									<div class="partner-shareholder-detail-vip-iden ml4 text-over4">{{detail.role_means}}</div>
								</div>
								
								<div class="mt15 flex alcenter">
									<i class="iconfont iconcall_gray mr5 ft12 cl-notice"/>
									<div class="cl-info ft12 ftw400 text-over4">{{detail.mobile}}</div>
								</div>
							</div>
						</div>
					</div>
					
					<div class="partner-shareholder-detail-left-content">
						<div class="partner-shareholder-detail-left-content-item">
							<div class="flex space alcenter">
								<div class="ft14 ftw400 cl-main">自定义身份</div>
								<div class="ft20 ftw600 cl-theme">{{detail.custom_name}}</div>
							</div>
						</div>
						
			
						
						<div class="partner-shareholder-detail-left-content-item mt20">
							<div class="flex space alcenter">
								<div class="ft14 ftw400 cl-main">客户</div>
								<div class="ft20 ftw600 cl-theme">{{detail.customer_num}}人</div>
							</div>
						</div>
						
						<div class="partner-shareholder-detail-left-content-item mt20">
							<div class="flex space alcenter">
								<div class="ft14 ftw400 cl-main">分红比例</div>
								<div class="ft20 ftw600 cl-theme">{{detail.custom_rate}}%</div>
							</div>
						</div>
						
						<div class="partner-shareholder-detail-left-content-item mt20">
							<div class="flex space alcenter">
								<div class="ft14 ftw400 cl-main">分红收益</div>
								<div class="ft20 ftw600 cl-theme">¥{{detail.profit}}</div>
							</div>
						</div>
					</div>
					
				</div>
			
				<div class="partner-shareholder-detail-right">
					<a-layout>
						<a-layout-header class="layout-classes-header">
							<a-menu class="classes-menu" :defaultSelectedKeys="[selectCurrent]" @click="selectMenu" mode="horizontal">
								<a-menu-item key="income">收益明细</a-menu-item>
								<a-menu-item key="customer">客户</a-menu-item>
							</a-menu>
						</a-layout-header>
						<a-layout-content class="layout-classes-content">
							<div v-if="selectCurrent == 'income'">
								<income-detail :partner_id="partner_id"></income-detail>
							</div>
							<div v-if="selectCurrent == 'customer'">
								<customer-detail :partner_id="partner_id"></customer-detail>
							</div>
						</a-layout-content>
					</a-layout>
				</div>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import incomeDetail from './components/CustomDetail/income.vue';
	import customerDetail from './components/CustomDetail/customer.vue';
	export default{
		components:{
			incomeDetail,
			customerDetail
		},
		data(){
			return{
				loading:false,
				selectCurrent: 'income',
				partner_id:0,
				detail:{
					name:'',
					mobile:'',
					share_capital:0,
					profit_rate:0,
					profit:0,
					withdrawable_profit:0,
					role_means:'',
					customer_num:0,
					custom_name:'',
					custom_rate:'',
				}
			}
		},
		created() {
			if (!this.$route.query.id) {
				this.partner_id=0;
			} else {
				this.partner_id = parseInt(this.$route.query.id);
			}
			this.loaddata();
		},
		methods:{
			
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getPartnerDetail',{
					partner_id:this.partner_id,
					role_id:4,
				}).then(res=>{
					this.detail=res.detail;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			
			selectMenu(e) {
				this.selectCurrent = e.key;
			},
			
			
			
		}
	}
</script>

<style>
	.partner-shareholder-detail-left{
		width: 400px;
		min-height: 800px;
		border-right: 1px solid #EBEDF5;
	}
	.partner-shareholder-detail-left-header{
		/* height: 128px; */
		padding: 24px;
		border-bottom: 1px solid #EBEDF5;
	}
	
	.partner-shareholder-detail-left-header-action{
		width: 272px;
		height: 50px;
		background: #FFFFFF;
		border-radius: 8px;
		border: 1px solid #EBEDF5;
		padding: 0px 20px 0px 24px;
	}
	.partner-shareholder-detail-face{
		width: 80px;
		height: 80px;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
		border-radius: 40px;
		border: 2px solid #FFFFFF;
	}
	.partner-shareholder-detail-vip-iden{
		padding: 2px 6px 2px 6px;
		border-radius: 2px;
		border: 1px solid #EBEDF5;
		
		font-size: 12px;
		font-weight: 600;
		color: #4772FF;
	}
	.partner-shareholder-detail-left-content{
		padding: 24px;
	}
	
	.partner-shareholder-detail-left-content-item{
		width: 100%;
		height: 72px;
		background: #F7F8FC;
		border-radius: 8px;
		padding: 20px;
	}
	
	.partner-shareholder-detail-left-footer{
		padding: 24px;
	}
	
	.partner-shareholder-detail-left-footer-item{
		width: 80px;
		height: 80px;
		background: #FFFFFF;
		box-shadow: 0px 4px 16px -4px rgba(0, 0, 0, 0.06);
		border-radius: 8px;
		cursor: pointer;
		padding: 15px 10px 15px 10px;
	}
	
	.partner-shareholder-detail-right{
		width: 100%;
	}
	
	
	
	.layout-classes-header {
		background: #FFFFFF;
	}
	
	.layout-classes-header {
		padding: 0 20px;
		height: 58px;
		line-height: 57px;
		border-bottom: 1px solid #F0F3F5;
		width: 100%;
	}
	
	.layout-classes-content {
		padding: 0px 0px 10px 0px;
		background: #FFFFFF;
		width: 100%;
	}
	
	.classes-menu {
		border-bottom: none;
		line-height: 55px;
		font-size: 14px;
	}
</style>
